<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <div class="title">
        <div class="contentTitle">
          <div class="contentOne">{{ titleName.connectionsName }}</div>
          <div class="contentTwo">创建并保存您想要维护的联系人脉圈</div>
        </div>
        <div class="titleRight cursor-p titleRight-nav">
          <a-button
            type="primary"
            class="marginRight5"
            @click="handleAddCircleList"
          >
            新增人脉圈
          </a-button>
        </div>
      </div>
      <div class="mt-40">
        <contact-grid
          ref="gridRef"
          allow-selection
          :showHeader="true"
          :columns="columns"
          :code="$route.path"
          :url="url"
          :items="items"
          :btn-operation="btnOperation"
          :url-params="parmas"
          :scroll="{ y: gridHeight }"
          @dataChange="dataChange"
          @fnGrid="handleGrid"
        >
          <template #operation="{ record }">
            <operation :options="options" :record="record" />
          </template>
          <template #size="{ record }">
            <div>{{ record.size }}人</div>
          </template>
          <template #todoSize="{ record }">
            <div>{{ record.todoSize }}</div>
          </template>
          <template v-slot:icon="{ record }">
            <component
              v-if='record.icon'
              :is="icons[`${record.icon}`]"
              class="marginBottom5 iconClass"
              @click="handleGoToContact(record)"
            ></component>
            <component
              v-else
              :is="icons[`UserOutlined`]"
              class="marginBottom5 iconClass"
              @click="handleGoToContact(record)"
            ></component>

          </template>
          <template v-slot:connectionsName="{ record }">
            <div class="cursor-p" @click="handleGoToContact(record)">
              {{ record.connectionsName }}
            </div>
          </template>
        </contact-grid>
      </div>
    </div>
  </div>

  <add-contacts-circle
    v-model:visible="visible"
    :init="initValue"
    @fnOk="handleAddCircle"
  />
  <add-page
    v-model:visible="visibleAdd"
    :init-value="initValueAdd"
    @fnOk="handleAddPage"
  />
  <cr-edit-page
    v-model:visible="editPage"
    :init="activeItem"
    @fnOk="handleEditPage"
  />
</template>

<script>
import contactGrid from '@/components/contactGrid';
import Operation from '@/components/operation';
import { reactive, ref, toRefs } from 'vue';
import addContactsCircle from '@/views/contactsCircle/addContactsCircle';
import addPage from '@/views/contactsCircle/addPage';
import contactsCircleApi from '@/api/contactsCircle';
import * as icons from '@ant-design/icons-vue';
import {
  message,
  // Popover,
  // Tooltip,
} from 'ant-design-vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import crEditPage from '@/views/contactsCircle/detailsPage/components/crEditPage';

// import { reactive, ref, toRefs } from 'vue';
// import { useStore } from 'vuex';

export default {
  components: {
    contactGrid,
    Operation,
    // APopover: Popover,
    addContactsCircle,
    addPage,
    Icon: icons,
    crEditPage,
    // ATooltip: Tooltip,
  },

  setup() {
    const gridRef = ref();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const userId = store.state.account.user.id;
    const { id } = route.params;

    const state = reactive({
      visible: false,
      initValue: {},
      visibleAdd: false,
      initValueAdd: {},
      parmas: {
        userId: userId,
        id: id,
        orderByType: '0',
      },
      titleName: {},
      orderByTypeList: [
        { value: '1', label: '首名字A->Z', id: '2' },
        { value: '2', label: '最新添加', id: '3' },
      ],
      editPage: false,
      activeItem: {},
      name: '',
    });

    contactsCircleApi.getTitleName('', id).then(res => {
      if (res) {
        state.titleName = res;
      }
    });

    return {
      ...toRefs(state),
      icons,
      userId,
      id,
      gridRef,
      handleGoToContact(record) {
        // router.push(`/contacts/${record.id}`);
        router.push(`/contactsCircle/${route.params.id}/${record.id}`);
      },

      items: [
        {
          key: 'orderByType',
          label: '排序',
          type: 'select',
          dataset: state.orderByTypeList,
        },
        { key: 'groupName', label: '搜索名称', type: 'input' },
      ],
      columns: [
        {
          dataIndex: 'icon',
          title: '图标',
          width: 50,
          slots: { customRender: 'icon' },
        },
        {
          dataIndex: 'connectionsName',
          title: '分组名称',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'connectionsName' },
        },
        { dataIndex: 'description', title: '描述', width: 120, ellipsis: true },
        {
          dataIndex: 'size',
          title: '人数',
          width: 300,
          ellipsis: true,
          slots: { customRender: 'size' },
        },
        {
          dataIndex: 'todoSize',
          title: '未完成待办',
          width: 300,
          ellipsis: true,
          slots: { customRender: 'todoSize' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'operation' },
        },
      ],
      // gridRef.value.getSelectedRowsKeys().length
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        // {
        //   type: 'sort',
        //   label: '排序',
        //   permission: 'resources:sort',
        //   fnClick: () => {
        //     console.log('排序排序', gridRef.value.getSelectedRowsKeys());
        //   },
        // },
        {
          type: 'delete',
          label: '删除',
          permission: 'resources:delete',
          icon: 'DeleteOutlined',
          fnClick: () => {
            //删除
            contactsCircleApi
              .deleteByConnectionIds('', {
                userId: store.state.account.user.id,
                // connectionsIds: gridRef.value.getSelectedRowsKeys(),
                connectionsIds: gridRef.value.getSelectedRowsKeysAll().id,
                pageDTO: {
                  conditionDto: state.parmas,
                  groupName: state.name
                },
                selectAll: gridRef.value.getSelectedRowsKeysAll().selectAll,
                parentId: id,

              })
              .then(res => {
                if (res) {
                  message.success('删除成功');
                  gridRef.value.refreshGrid(
                    gridRef.value.getSelectedRowsKeysAll().id.length,
                  );
                  // gridRef.value.refreshGrid();
                }
              });
          },
        },
      ],
      options: [
        {
          type: 'add',
          permission: 'distribution:add',
          icon: 'PlusOutlined',
          fnClick: record => {
            state.initValue = record.id;
            state.visible = true;
          },
        },
        {
          type: 'toDo',
          icon: 'DiffOutlined',
          permission: 'distribution:toDo',
          fnClick: record => {
            state.editPage = true;
            let parmas = {
              connectionsId: record.id,
            };
            Object.assign(state.activeItem, parmas);
          },
        },
        {
          type: 'detail',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: 'distribution:detail',
          fnClick: record => {
            router.push(`/contactsCircle/${id}/${record.id}`);
          },
        },
      ],
      url: contactsCircleApi.getPageUrl,
      gridHeight: document.body.clientHeight - 386,
      // setAvatar,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleAddCircle(value) {
        state.visible = value.visible;
        gridRef.value.refreshGrid();
      },
      handleAddCircleList() {
        state.visibleAdd = true;
      },
      handleAddPage(value) {
        state.visibleAdd = value;
        gridRef.value.refreshGrid();
      },
      handleEditPage(value) {
        state.editPage = value;
        gridRef.value.refreshGrid();
      },
      // 父子传值
      handleGrid() {},
      getSearch: parmas => {
        state.name = parmas.name
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-main-grid {
  height: calc(100vh - 88px);
  //max-height: calc(100vh - 88px);
  overflow-y: auto;
  background: #fff;
  padding: 32px 54px;
}
.iss-main-grid /deep/ .ant-table-placeholder {
  border-bottom: none;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // .contentTitle {
  // }
  .titleRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30%;

    .titleRight-nav {
      display: flex;
      justify-content: right;
      :deep(.ant-btn) {
        background: #ff7b00;
        border: 1px solid #ff7b00;
        color: #fff;
      }

      .operate {
        margin-left: 15px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        text-align: center;
        opacity: 1;
        background: #ffffff;
        box-sizing: border-box;
        border: 1px solid #eeeff5;
        color: #333;
        font-size: 20px;
      }
    }

    .operate {
      border: 1px solid #eeeff5;
      background: #ffffff;
      border-radius: 4px;
      padding: 3px 6px;
    }
  }
}
.contentOne {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #333333;
}
.contentTwo {
  font-size: 12px;
  margin-top: 8px;
}
.contentAdd {
  font-size: 14px;
  background: #ff7b00;
  padding: 9px 22px;
  color: #fff;
  border-radius: 4px;
  opacity: 1;
  cursor: pointer;
}
.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.iconClass {
  font-size: 24px;
  color: #ff7b00;
}

/deep/ .ant-table-column-title {
  white-space: nowrap;
}
</style>
